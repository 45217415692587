import axios from "axios";

import Routes from "@/lib/ApiRoutes";

class ApiClient {
  constructor() {
    this.Routes = Routes;
    this.client = axios.create();
  }

  fetch(path, options = {}) {
    const { method, params, data } = options;
    const headers = { ...options.headers };

    return this.client.request({
      url: path,
      responseType: "json",
      method,
      headers,
      params,
      data
    });
  }

  get(path, options = {}) {
    return this.fetch(path, { method: "GET", ...options });
  }

  post(path, options = {}) {
    return this.fetch(path, { method: "POST", ...options });
  }
}

export default new ApiClient();
