<template>
  <player-overlay>
    <div class="overlay__nav-wrapper">
      <ul class="overlay__nav">
        <li
          v-for="tab in tabs"
          :key="tab.key"
          :class="[{ active: activeTab === tab.key }, 'overlay__nav-item']"
          @click="activateTab(tab.key)"
        >
          <span class="tab__icon" v-html="tab.image"></span>
          {{ tab.title }}
        </li>
      </ul>
    </div>
    <overlay-content-wrapper>
      <div>
        <template v-if="activeTab === 'showNotes'">
          <player-show-notes :podcast="podcast" :episode="episode" />
        </template>
        <template v-else-if="activeTab === 'people'">
          <player-people :people="episode.people" />
        </template>
      </div>
    </overlay-content-wrapper>
  <svg xmlns="http://www.w3.org/2000/svg" style="display: none !important;"><symbol id="svg-sprite-57ff1b733e2a4e027134616c15d1dd3d" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="inherit"><path fill="none" d="M0 0h32v32H0z"/><path d="M19 19H7v2h12v-2zm0-8H7v2h12v-2zM7 17h19v-2H7v2zM7 7v2h19V7H7z"/></g></symbol><symbol id="svg-sprite-29686aeecfeb0371904fb0cadd867344" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="inherit"><path fill="none" d="M0 0h32v32H0z"/><path d="M20.005 15A2.99 2.99 0 0023 12c0-1.66-1.332-3-2.995-3A2.998 2.998 0 0017 12c0 1.66 1.342 3 3.005 3zm-8 0A2.99 2.99 0 0015 12c0-1.66-1.332-3-2.995-3A2.998 2.998 0 009 12c0 1.66 1.342 3 3.005 3zM12 17c-2.33 0-7 1.17-7 3.5V23h14v-2.5c0-2.33-4.67-3.5-7-3.5zm7.974 0c-.291 0-.623.02-.974.05 1.164.84 1.977 1.97 1.977 3.45V23H27v-2.5c0-2.33-4.688-3.5-7.026-3.5z"/></g></symbol></svg></player-overlay>
</template>

<script>
import PlayerOverlay from "@/components/player/PlayerOverlay";
import OverlayContentWrapper from "@/components/player/OverlayContentWrapper";
import PlayerShowNotes from "@/components/player/PlayerShowNotes";
import PlayerPeople from "@/components/player/PlayerPeople";

export default {
  props: {
    episode: { type: Object, required: true },
    podcast: { type: Object, required: true }
  },

  components: {
    PlayerShowNotes,
    PlayerOverlay,
    OverlayContentWrapper,
    PlayerPeople
  },

  data() {
    return {
      activeTab: "showNotes"
    };
  },

  computed: {
    tabs() {
      let tabs = [
        {
          key: "showNotes",
          title: "Show Notes",
          image: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewbox="0 0 32 32" v-bind:svg-inline="''" v-bind:svg-sprite="''" v-bind:viewbox="'0 0 32 32'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><use xlink:href="#svg-sprite-57ff1b733e2a4e027134616c15d1dd3d" href="#svg-sprite-57ff1b733e2a4e027134616c15d1dd3d"></use></svg>`
        }
      ];

      if (this.episode.people.length > 0) {
        tabs.push({
          key: "people",
          title: "People",
          image: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewbox="0 0 32 32" v-bind:svg-inline="''" v-bind:svg-sprite="''" v-bind:viewbox="'0 0 32 32'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><use xlink:href="#svg-sprite-29686aeecfeb0371904fb0cadd867344" href="#svg-sprite-29686aeecfeb0371904fb0cadd867344"></use></svg>`
        });
      }

      return tabs;
    }
  },

  methods: {
    activateTab(tabName) {
      this.activeTab = tabName;
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay__nav-wrapper {
  width: 100%;
  height: 32px;
}

.overlay__nav {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  height: 100%;
}

.overlay__nav-item {
  padding: 8px 12px 0;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  cursor: pointer;
  color: var(--text-color);
  opacity: 0.8;

  &.active {
    color: var(--primary-color);
    opacity: 1;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      top: 0;
      background: var(--primary-color);
    }
  }
}

.overlay__content {
  padding: 8px 8px 80px;
}
</style>

<style lang="scss">
.overlay__nav-item {
  .tab__icon svg {
    use {
      fill: var(--text-color);
    }
  }

  &.active {
    .tab__icon svg {
      use {
        fill: var(--primary-color);
      }
    }
  }
}

.tab__icon {
  svg {
    position: relative;
    top: 3px;
    margin-right: 5px;
    height: 24px;
    width: 24px;
  }
}

@media screen and (max-width: $breakpoint--s) {
  .tab__icon {
    display: none;
  }
}
</style>

