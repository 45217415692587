<template>
  <ul class="episodes">
    <li
      :class="[{ active: _episode.guid === episode.guid }, 'episode']"
      @click="$emit('episodeSelected', _episode)"
      v-for="_episode in podcast.episodes"
      :key="_episode.guid"
    >
      <div class="episode__details">
        <player-play small :class="[{ 'opacity-50': !isEpisodePlaying(_episode) }, 'play-icon']" @togglePlayback="episodeSelected" :is-playing="isEpisodePlaying(_episode)" />

        <span class="episode__title" v-html="episodeLabel(_episode)"></span>
      </div>
      <span class="episode__duration">{{ formatDuration(_episode.duration) }}</span>
    </li>
  </ul>
</template>

<script>
import PlayerPlay from "@/components/player/PlayerPlay";

export default {
  components: {
    PlayerPlay
  },

  props: {
    podcast: { type: Object, required: true },
    episode: { type: Object, required: true },
    isPlaying: { type: Boolean, default: false },
  },

  methods: {
    isEpisodePlaying(_episode) {
      return this.isPlaying && this.episode.guid === _episode.guid
    },

    formatDuration(duration) {
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const formattedMinutes = `0${minutes}`.slice(-2);

      if (hours < 1) {
        return `${formattedMinutes}m`;
      }

      return `${hours}h ${formattedMinutes}m`;
    },

    episodeLabel(episode) {
      let season = '';
      let type = '';
      let prefix = ' - ';

      if (episode.season) {
        season = `S${episode.season} `;
      }

      if (episode.type !== 'full') {
        type = ` ${episode.type.toUpperCase()} `;
        prefix = `<span class="badge pale">${type} </span>`;
      }

      return `<strong>${season}EP ${episode.number} </strong>${prefix}${episode.title}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.episodes {
  list-style: none;
  padding: 0;
  margin: 0;
}

.episode {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  font-size: 15px;
  color: var(--text-color);
  cursor: pointer;

  &:hover {
     background: rgba(black, 0.04);
  }

  &.active {
    background: rgba(black, 0.1);
  }
}

.episode__duration {
  font-size: 11px;
  opacity: 0.5;
}

.play-icon {
  margin-right: 8px;
  outline: none;
}

.opacity-50 {
  opacity: 0.5;
}

.episode__details {
  display: flex;
  align-items: center;

    svg use {
    fill: var(--primary-color);
  }
}


</style>
