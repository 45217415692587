<template>
  <player-overlay>
    <overlay-content-wrapper>
      <div class="d-flex">
        <div class="form-group mr-2">
          <label>Share</label>
          <div class="form-group__inline">
            <a
              :href="
                `https://x.com/intent/tweet?text=${episode.sharePath}`
              "
              class="social-link"
              target="_blank"
            >
              <svg width="16" height="14" viewBox="0 0 1227 1227" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M714.163 519.284L1160.89 0h-105.86L667.137 450.887 357.328 0H0l468.492 681.821L0 1226.37h105.866l409.625-476.152 327.181 476.152H1200L714.137 519.284h.026zM569.165 687.828l-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721H892.476L569.165 687.854v-.026z" fill="#000"/></svg>
            </a>
            <a
              :href="
                `https://www.facebook.com/sharer/sharer.php?u=${episode.sharePath}`
              "
              class="social-link"
              target="_blank"
            >
              <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 2.5C15 1.187 13.814 0 12.5 0h-10C1.186 0 0 1.187 0 2.5v10C0 13.813 1.186 15 2.5 15h5V9.333H5.667v-2.5H7.5V5.86c0-1.68 1.261-3.192 2.813-3.192h2.02v2.5h-2.02c-.222 0-.48.268-.48.67v.996h2.5v2.5h-2.5V15H12.5c1.314 0 2.5-1.187 2.5-2.5v-10z" fill="#000" fill-rule="nonzero"/></svg>
            </a>
          </div>
        </div>
        <div class="form-group">
          <label>Download</label>
          <a
            :href="`${episode.downloadPath}?source=embed-download`"
            target="_blank"
            class="btn btn-secondary"
          >
            <svg viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'download-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="inherit"><path fill="none" d="M0 0h56v56H0z"/><path d="M5 45.25h46V51H5zm15.813-14.375L28 39.5l7.188-8.53 7.187-8.72H33.75V5h-11.5v17.25h-8.625z"/></g></svg>
            Download
          </a>
        </div>
      </div>
      <div class="form-group">
        <label for="link">Link</label>
        <div class="form-group__inline --link-w-time">
          <input
            :value="episode.sharePath"
            type="text"
            class="form-control"
            id="link"
            ref="linkInput"
            readonly
          />
          <span class="timestamp__check">
            <input type="checkbox" value="withTimestamp" id="at" v-model="withTimestamp" />
            <label class="form-inline-label" for="at">at</label>
          </span>
          <input type="text" class="form-control" id="timestamp" v-model="timestamp" ref="tsInput" />

          <span @click="copyLink">
            <template v-if="copiedFrom.linkInput">
              <svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'copy-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="inherit"><path fill="none" d="M0 0h80v80H0z"/><path d="M33.225 56.927l-.096-.084-.088.084-14.844-14.845 5.278-5.274 9.654 9.67 23.396-23.405 5.274 5.278-28.574 28.576zM39.996 6C21.227 6 6 21.228 6 40.002S21.227 74 39.996 74C58.769 74 74 58.776 74 40.002 74 21.228 58.769 6 39.996 6z"/></g></svg>
            </template>
            <template v-else>
              <svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'copy-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="inherit"><path fill="none" d="M0 0h80v80H0z"/><path d="M52.264 8H16.896C13.656 8 11 10.739 11 14.089v42.637h5.896V14.089h35.368V8zm-2.948 12.178L67 38.456V68.91c0 3.351-2.652 6.09-5.896 6.09H28.656c-3.24 0-5.868-2.739-5.868-6.089l.032-42.637c0-3.35 2.624-6.089 5.864-6.089H49.32l-.004-.007zm-2.948 21.32H62.58L46.368 24.749v16.749z"/></g></svg>
            </template>
          </span>
        </div>
      </div>
      <div class="form-group">
        <label for="embed">Embed</label>
        <div class="form-group__inline">
          <input
            type="text"
            class="form-control"
            id="embed"
            ref="embedInput"
            :value="episode.embed"
            readonly
          />
          <span @click="copyTextFrom('embedInput')">
            <template v-if="copiedFrom.embedInput">
              <svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'copy-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="inherit"><path fill="none" d="M0 0h80v80H0z"/><path d="M33.225 56.927l-.096-.084-.088.084-14.844-14.845 5.278-5.274 9.654 9.67 23.396-23.405 5.274 5.278-28.574 28.576zM39.996 6C21.227 6 6 21.228 6 40.002S21.227 74 39.996 74C58.769 74 74 58.776 74 40.002 74 21.228 58.769 6 39.996 6z"/></g></svg>
            </template>
            <template v-else>
              <svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'copy-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="inherit"><path fill="none" d="M0 0h80v80H0z"/><path d="M52.264 8H16.896C13.656 8 11 10.739 11 14.089v42.637h5.896V14.089h35.368V8zm-2.948 12.178L67 38.456V68.91c0 3.351-2.652 6.09-5.896 6.09H28.656c-3.24 0-5.868-2.739-5.868-6.089l.032-42.637c0-3.35 2.624-6.089 5.864-6.089H49.32l-.004-.007zm-2.948 21.32H62.58L46.368 24.749v16.749z"/></g></svg>
            </template>
          </span>
        </div>
      </div>
      <div class="form-group">
        <label for="RSS">RSS</label>
        <div class="form-group__inline">
          <input
            type="text"
            class="form-control"
            id="RSS"
            ref="rssInput"
            :value="podcast.rss"
            readonly
          />
          <span @click="copyTextFrom('rssInput')">
            <template v-if="copiedFrom.rssInput">
              <svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'copy-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="inherit"><path fill="none" d="M0 0h80v80H0z"/><path d="M33.225 56.927l-.096-.084-.088.084-14.844-14.845 5.278-5.274 9.654 9.67 23.396-23.405 5.274 5.278-28.574 28.576zM39.996 6C21.227 6 6 21.228 6 40.002S21.227 74 39.996 74C58.769 74 74 58.776 74 40.002 74 21.228 58.769 6 39.996 6z"/></g></svg>
            </template>
            <template v-else>
              <svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'copy-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="inherit"><path fill="none" d="M0 0h80v80H0z"/><path d="M52.264 8H16.896C13.656 8 11 10.739 11 14.089v42.637h5.896V14.089h35.368V8zm-2.948 12.178L67 38.456V68.91c0 3.351-2.652 6.09-5.896 6.09H28.656c-3.24 0-5.868-2.739-5.868-6.089l.032-42.637c0-3.35 2.624-6.089 5.864-6.089H49.32l-.004-.007zm-2.948 21.32H62.58L46.368 24.749v16.749z"/></g></svg>
            </template>
          </span>
        </div>
      </div>
    </overlay-content-wrapper>
  </player-overlay>
</template>

<script>
import PlayerOverlay from "@/components/player/PlayerOverlay";
import OverlayContentWrapper from "@/components/player/OverlayContentWrapper";
import copyText from "@/lib/copyText";
export default {
  props: {
    episode: { type: Object, required: true },
    podcast: { type: Object, required: true },
    currentTimestamp: { type: Number, required: true }
  },

  components: {
    PlayerOverlay,
    OverlayContentWrapper
  },

  data() {
    return {
      withTimestamp: false,
      timestamp: "00:00",
      copiedFrom: { linkInput: false, embedInput: false, rssInput: false }
    };
  },

  mounted() {
    this.timestamp = this.convertTsToHumanReadable(
      Math.floor(this.currentTimestamp)
    );
  },

  methods: {
    convertTsToHumanReadable(timeInSeconds) {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);

      return `${minutes}:${seconds}`;
    },

    converToTs(humanReadableTime) {
      const splitTime = humanReadableTime.split(":");

      const minutes = parseInt(splitTime[0]) * 60;
      const seconds = parseInt(splitTime[1]);

      return minutes + seconds;
    },

    copyLink() {
      if (this.withTimestamp) {
        const orgLink = this.episode.sharePath;
        const ts = this.converToTs(this.timestamp);
        this.episode.sharePath = `${this.episode.sharePath}?ts=${ts}`;

        this.$nextTick(() => {
          this.copyTextFrom("linkInput");
          this.episode.sharePath = orgLink;
        });
      } else {
        this.copyTextFrom("linkInput");
      }
    },

    copyTextFrom(inputRef) {
      this.copiedFrom = {};

      Object.values(this.$refs).forEach(el => {
        el.classList.remove("focused");
      });

      copyText(this.$refs[inputRef]);

      if (inputRef == "linkInput" && this.withTimestamp) {
        this.$refs["tsInput"].classList.add("focused");
      }

      this.copiedFrom[inputRef] = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.d-flex {
  display: flex;
}

.mr-2 {
  margin-right: 16px;
}

#timestamp {
  width: 64px;
}

.timestamp__check {
  display: flex;
  margin: 0 8px;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 1px solid var(--text-color);
  opacity: 0.8;
  transition: opacity 0.2s ease;
  margin-right: 8px;

  svg path,
  svg g {
    fill: var(--text-color);
  }

  &:hover {
    opacity: 1;
  }
}

.download-icon {
  height: 14px;
  width: 14px;
  fill: var(--text-color);
}

@media screen and (max-width: $breakpoint--s) {
  .form-group__inline.--link-w-time {
    flex-wrap: wrap;

    .form-control {
      margin-bottom: 8px;
    }

    #timestamp {
      margin-right: auto;
    }
  }
}
</style>
