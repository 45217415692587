<template>
  <div class="player-details" ref="details">
    <h2
      :class="[{ isOverflowing }]"
      ref="heading"
      :style="{ width: `100%` }"
    >
      <span>{{ episode.title }}&nbsp;</span>
      <template v-if="isOverflowing">
        <span>{{ episode.title }}&nbsp;</span>
      </template>
    </h2>
    <h6 class="ep-title" v-html="episodeTitle"></h6>
  </div>
</template>

<script>
import throttle from "lodash/throttle";

export default {
  data() {
    return {
      baseHeadingWidth: undefined,
      headingWidth: undefined,
      isOverflowing: false,
      resizeHandler: throttle(this.handleHeadingScroll, 250),
    };
  },

  props: {
    episode: { type: Object, required: true },
    podcast: { type: Object, required: true },
  },

  methods: {
    handleHeadingScroll() {
      const heading = this.$refs.heading;
      const details = this.$refs.details;

      this.isOverflowing =
        details.clientWidth <
        (this.isOverflowing ? heading.scrollWidth / 2 : heading.scrollWidth);
    },

    setDocumentTitle() {
      document.title = this.episode.title;
    }
  },

  watch: {
    episode() {
      this.setDocumentTitle();
    },
  },

  computed: {
    episodeTitle() {
      let title = `<strong>${this.podcast.title}</strong>`;
      let season = '';
      let type = '';
      let badge = '';

      if (this.episode.season) {
        season = ` - SEASON ${this.episode.season}`;
      }

      if (this.episode.type !== 'full') {
        type = this.episode.type.charAt(0) + this.episode.type.slice(1);
        badge = `<span class="badge">${type}</span>`;
      }

      return `${title}${season} - EPISODE ${this.episode.number} ${badge}`;
    },
  },

  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },

  mounted() {
    this.headingWidth = this.$refs.heading.scrollWidth;
    this.setDocumentTitle();
    this.handleHeadingScroll();
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>

<style lang="scss" scoped>
.player-details {
  margin-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2,
  h6 {
    color: var(--text-color);
  }

  h2 {
    span {
      display: inline-block;
      min-width: 100%;
    }

    &.isOverflowing {
      &:hover {
        animation: scroll-left 15s linear infinite;
      }
      @media screen and (max-width: $breakpoint--s) {
      animation: scroll-left 15s linear infinite;
      }
    }

    
  }

  h6 {
    opacity: 0.5;
  }
}

@keyframes scroll-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@media screen and (max-width: $breakpoint--s) {
  .player-details {
    margin-left: 20px;
  }
}
</style>
