<template>
  <div>
    <h6 class="episode-title" v-html="episodeTitle">    
    </h6>
    <h6 class="author">By: {{ podcast.author }}</h6>
    <h6 class="pub-date"></h6>
    <div class="show-notes" v-html="episode.showNotes"></div>
  </div>
</template>

<script>
export default {
  props: {
    podcast: { type: Object, required: true },
    episode: { type: Object, required: true }
  },

  computed: {
    episodeTitle() {
      let title = `<strong>${this.podcast.title}`;
      let season = '';

      if (this.episode.season) {
        season = ` - SEASON ${this.episode.season}`;
      }
        
      return `${title}${season} - EPISODE ${this.episode.number} - ${this.episode.formattedPublicationDate}`;
    },
  },

};
</script>

<style lang="scss" scoped>
.episode-title {
  opacity: 0.8;
  color: var(--text-color);
}

.pub-date {
  opacity: 0.7;
  color: var(--text-color);
}

.author {
  opacity: 0.5;
  margin-bottom: 12px;
  color: var(--text-color);
}
</style>

<style lang="scss">
.show-notes {
  p,
  a,
  ul,
  ol,
  hr,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote {
    color: var(--text-color);
    margin-top: 4px;
    font-size: 13px;
  }

  img {
    max-width: 400px;
    display: block;
    margin-bottom: 8px;
  }
}
</style>
