<template>
  <player-overlay>
    <overlay-content-wrapper>
      <template v-if="subscribed">
        <div class="submission-text">
          <h3>{{ submissionHeading }}</h3>
          <p>{{ submissionBody }}</p>
          <a
            target="_blank"
            :href="buttonUrl"
            class="btn btn-primary"
            :disabled="subscribing"
          >{{ buttonText }}</a>
        </div>
      </template>
      <template v-else>
        <template v-if="error">
          <div class="error">
            <p>Something went wrong. Please try again</p>
          </div>
        </template>
        <template v-if="podcast.collect_emails">
        <form @submit="submitForm">
          <div class="form-group">
            <label for="email">Subscribe to updates from {{ podcast.title }}</label>
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="Your email address"
                v-model="email"
              />
            </div>
            <button type="submit" class="btn btn-primary">Subscribe</button>
          </div>
        </form>
        </template>
      </template>

      <div class="podcast-badges">
        <template v-if="podcast.platformLinks.apple_podcasts">
          <a :href="podcast.platformLinks.apple_podcasts" target="_blank">
            <img
              class="podcast-badge"
              :src="require('@/assets/images/apple-podcasts.svg')"
              alt="Apple Podcasts"
            />
          </a>
        </template>
        <template v-if="podcast.platformLinks.spotify_podcasts">
          <a :href="podcast.platformLinks.spotify_podcasts" target="_blank">
            <img
              class="podcast-badge"
              :src="require('@/assets/images/spotify-podcasts.svg')"
              alt="Spotify Podcasts"
            />
          </a>
        </template>
      </div>

      <div class="form-group">
        <label for="RSS">RSS</label>
        <div class="form-group__inline">
          <input
            type="text"
            class="form-control"
            id="RSS"
            ref="rssInput"
            :value="podcast.rss"
            readonly
          />
          <span @click="copy">
            <template v-if="copied">
              <svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'copy-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="inherit"><path fill="none" d="M0 0h80v80H0z"/><path d="M33.225 56.927l-.096-.084-.088.084-14.844-14.845 5.278-5.274 9.654 9.67 23.396-23.405 5.274 5.278-28.574 28.576zM39.996 6C21.227 6 6 21.228 6 40.002S21.227 74 39.996 74C58.769 74 74 58.776 74 40.002 74 21.228 58.769 6 39.996 6z"/></g></svg>
            </template>
            <template v-else>
              <svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'copy-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="inherit"><path fill="none" d="M0 0h80v80H0z"/><path d="M52.264 8H16.896C13.656 8 11 10.739 11 14.089v42.637h5.896V14.089h35.368V8zm-2.948 12.178L67 38.456V68.91c0 3.351-2.652 6.09-5.896 6.09H28.656c-3.24 0-5.868-2.739-5.868-6.089l.032-42.637c0-3.35 2.624-6.089 5.864-6.089H49.32l-.004-.007zm-2.948 21.32H62.58L46.368 24.749v16.749z"/></g></svg>
            </template>
          </span>
        </div>
      </div>
    </overlay-content-wrapper>
  </player-overlay>
</template>

<script>
import Api from "@/lib/ApiClient.js";

import PlayerOverlay from "@/components/player/PlayerOverlay";
import OverlayContentWrapper from "@/components/player/OverlayContentWrapper";
import copyText from "@/lib/copyText";

export default {
  props: {
    podcast: { type: Object, required: true },
    episode: { type: Object, required: true }
  },

  data() {
    return {
      email: "",
      subscribed: false,
      subscribing: false,
      submissionHeading: "",
      submissionBody: "",
      buttonUrl: "",
      buttonText: "",
      error: false,
      copied: false
    };
  },

  components: {
    PlayerOverlay,
    OverlayContentWrapper
  },

  methods: {
    copy() {
      copyText(this.$refs.rssInput);
      this.copied = true;
      setTimeout(() => (this.copied = false), 3000);
    },

    submitForm(e) {
      e.preventDefault();

      if (this.email.length == 0) return;

      this.error = false;
      this.subscribing = true;

      Api.post(
        Api.Routes.emailSubscribe({
          email: this.email,
          guid: this.episode.guid
        })
      )
        .then(response => {
          this.subscribed = true;

          const {
            heading_text,
            body_text,
            button_text,
            button_url
          } = response.data.thanks_cta;

          this.submissionHeading = heading_text;
          this.submissionBody = body_text;
          this.buttonText = button_text;
          this.buttonUrl = button_url;
        })
        .catch(err => (this.error = true))
        .then(() => (this.subscribing = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.submission-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--text-color);

  h3,
  p {
    margin: 0;
  }

  h3 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 16px;
  }
}

.error {
  border: 1px solid red;
  color: red;
  padding: 8px;
  border-radius: 4px;

  p {
    margin: 0;
  }
}

.podcast-badges {
  margin-top: 16px;
}

.podcast-badge {
  height: 40px;
  margin-right: 8px;
}
</style>
