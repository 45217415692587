export default {
  emailSubscribe: ({ guid, email }) =>
    `${process.env.VUE_APP_APP_BASE_URL}/emails/subscribers?guid=${guid}&email=${email}`,
  episodeDetails: guid =>
    `${process.env.VUE_APP_API_BASE_URL}/embed/episode/${guid}`,
  podcastDetails: guid =>
    `${process.env.VUE_APP_API_BASE_URL}/embed/podcast/${guid}`,
  latestEpisodeDetails: guid =>
    `${process.env.VUE_APP_API_BASE_URL}/embed/podcast/${guid}/latest`,
};
