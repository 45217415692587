<template>
  <div class="people">
    <div v-for="person in people" :key="person.name" class="person">
      <img class="person__image" :src="person.avatar" :alt="person.name" />
      <div class="person__details">
        <p>
          <strong>{{ person.name }}</strong>
        </p>

        <a :href="person.twitter" v-if="person.twitter" target="_blank">
          <svg width="16" height="14" viewBox="0 0 1227 1227" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="person.twitter" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M714.163 519.284L1160.89 0h-105.86L667.137 450.887 357.328 0H0l468.492 681.821L0 1226.37h105.866l409.625-476.152 327.181 476.152H1200L714.137 519.284h.026zM569.165 687.828l-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721H892.476L569.165 687.854v-.026z" fill="#000"/></svg>
        </a>
        <a :href="person.facebook" v-if="person.facebook" target="_blank">
          <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" v-if="person.facebook" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 2.5C15 1.187 13.814 0 12.5 0h-10C1.186 0 0 1.187 0 2.5v10C0 13.813 1.186 15 2.5 15h5V9.333H5.667v-2.5H7.5V5.86c0-1.68 1.261-3.192 2.813-3.192h2.02v2.5h-2.02c-.222 0-.48.268-.48.67v.996h2.5v2.5h-2.5V15H12.5c1.314 0 2.5-1.187 2.5-2.5v-10z" fill="#000" fill-rule="nonzero"/></svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    people: { type: Array, required: true }
  }
};
</script>

<style lang="scss">
.people {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}

.person {
  display: flex;
  margin-right: 28px;
  margin-bottom: 24px;
}

.person__image {
  border-radius: 50px;
  box-shadow: 0 1px 2px rgba(black, 0.2);
  height: 40px;
  width: 40px;
  margin-right: 8px;
}

.person__details {
  p {
    font-size: 12px;
    margin: 2px 0 5px;
    color: var(--text-color);
  }

  svg {
    opacity: 0.6;
    margin-right: 8px;
    height: 14px;

    path {
      fill: var(--text-color);
    }

    &:hover {
      opacity: 1;
    }
  }
}
</style>
